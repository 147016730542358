import { graphql } from 'gatsby';
import * as React from 'react';
import { Link } from 'gatsby';

import GiveContentTrans from '../components/GiveContentTrans';
import SEO from '../components/seo';

import '../styles/layout/404.scss';

import Layout from '../components/Layout';

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);

    // HAcky solution to determine language in class based page
    let pageLanguage = 'et';
    if (
      props.path === '/en/404/' ||
      props.path === 'en/404/' ||
      props.path === '/en/404' ||
      props.path === 'en/404'
    ) {
      pageLanguage = 'en';
    }

    this.state = {
      uiLanguage: pageLanguage,
    };
  }

  render() {
    let formClasses = 'contact-form';
    if (this.state.submitting) {
      formClasses += ' form-loading';
    }

    return (
      <Layout>
      <main className="notfoundcontent">
        <GiveContentTrans language="et">
          <SEO
            title="Lehte ei leitud"
            description="Lehte ei leitud. "
            image="/pagecover.jpg"
          />
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <SEO
            title="Page not found"
            description="Page not found. "
            image="/pagecover-en-default.jpg"
          />
        </GiveContentTrans>
        <GiveContentTrans language="et">
          <title>404 Siin ei ole midagi</title>
          <h1>Siin ei ole midagi!</h1>
          <p>
            Pöördu <Link to="/">esilehele</Link>.
          </p>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <title>404 Not found</title>
          <h1>Nothing here!</h1>
          <p>
            Continue in
            <Link to="/">homepage</Link>.
          </p>
        </GiveContentTrans>

        <div className="img-100 section-margin" />
        <div className="bgvideo">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/yrctMvIWpG0?autoplay=1&mute=1&controls=0&start=60"
            title="Give 404"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
      </main>
      </Layout>
    );
  }
}

/* 
// markup
const NotFoundPage = () => (
  <main className="notfoundcontent">
    <GiveContentTrans language="et">
      <SEO
        title="Lehte ei leitud"
        description="Lehte ei leitud. "
        image="/pagecover.jpg"
      />
    </GiveContentTrans>
    <GiveContentTrans language="en">
      <SEO
        title="Page not found"
        description="Page not found. "
        image="/pagecover-en-default.jpg"
      />
    </GiveContentTrans>
    <GiveContentTrans language="et">
      <title>404 Siin ei ole midagi</title>
      <h1>Siin ei ole midagi!</h1>
      <p>
        Pöördu <Link to="/">esilehele</Link>.
      </p>
    </GiveContentTrans>
    <GiveContentTrans language="en">
      <title>404 Not found</title>
      <h1>Nothing here!</h1>
      <p>
        Continue in
        <Link to="/">homepage</Link>.
      </p>
    </GiveContentTrans>

    <div className="img-100 section-margin" />
    <div className="bgvideo">
      <iframe
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/yrctMvIWpG0?autoplay=1&mute=1&controls=0&start=60"
        title="Give 404"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </div>
  </main>
);
*/
export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
